.timelineContainer {
  height: auto;
}

.timelineItems {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.left {
  transform: translateX(-150px);
  justify-content: flex-end;
}

.right {
  transform: translateX(150px);
  justify-content: flex-start;
}

.timelineItem {
  width: 302px;
  height: 130px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.timelineCard {
  height: 80%;
  width: 100%;
  background-color: #1f1f1f;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-right: 20px;
  padding-left: 20px;
  padding: 20px;
  box-shadow: 5px 5px 5px #111111;
}

.timelineLine {
  height: 100%;
  width: 2px;
  background-color: white;
}

.timelineEnd {
  height: 50px;
}

.timelineCirle {
  position: relative;
  top: calc(50% - 5px);
  left: -5px;
  height: 10px;
  width: 10px;
  border-radius: 20px;
  border: 1px solid white;
  background-color: #3c5aaa;
}

.left > .timelineTriangle {
  border-left: 20px solid #1f1f1f;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent; 
  border-right:20px solid transparent; 
  height: 0px;
  width: 0px;
  margin-right: 10px;
}

.right > .timelineTriangle {
  border-right: 20px solid #1f1f1f;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent; 
  border-left:20px solid transparent; 
  height: 0px;
  width: 0px;
  margin-left: 10px;
}

.dateContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.left .dateContainer {
  border-left: 1px solid white;
  padding-left: 20px;
  margin-left: 20px;
}

.right .dateContainer {
  border-right: 1px solid white;
  padding-right: 20px;
  margin-right: 20px;
}

.yearSpan {
  font-size: 2em;
}

.employmentContainer {
  display: flex;
  flex-direction: column;
}

.location {
  font-size: 24px;
  margin-bottom: 10px;
}

.position {
  font-size: 16px;
}

@media only screen and (max-width: 768px) {

  .employmentContainer {
    margin-bottom: 10px;
  }

  .timelineCard {
    display: flex;
    width: 40%;
  }

  .left .timelineCard {
    flex-direction: column;
  }

  .right .timelineCard {
    flex-direction: column-reverse;
  }

  .location {
    font-size: 1em;
    margin-bottom: 5px;
  }

  .position {
    font-size: 0.5em;
  }

  .left > .timelineTriangle {
    border-left: 10px solid #1f1f1f;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent; 
    border-right:10px solid transparent; 
    height: 0px;
    width: 0px;
    margin-right: 10px;
  }
  
  .right > .timelineTriangle {
    border-right: 10px solid #1f1f1f;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent; 
    border-left:10px solid transparent; 
    height: 0px;
    width: 0px;
    margin-left: 10px;
  }
  
  .left .dateContainer {
    border:none;
    border-top: 1px solid white;
    padding-left: 0px;
    margin-left: 0px;
  }
  
  .right .dateContainer {
    border:none;
    border-top: 1px solid white;
    padding-right: 0px;
    margin-right: 0px;
  }

  .timelineCard {
    padding: 10px;
  }

  .location {
    font-size: 1em;

  }

  .monthSpan {
    font-size: 12px;
    margin-top: 10px;
  }

  .yearSpan {
    font-size: 16px;
  }
}
