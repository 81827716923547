.about-content {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: row;
    background-image: url('../../images/section2.jpg');
    background-size: cover;
    background-position: center;
    overflow: hidden;
}

.infoRoot{
    top: 5%;
    left: 5%;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.resumeButton {
    position: absolute;
    right: 0;
    bottom: 0;
    margin: 15px;
    background-color: rgba(0, 0, 0, 0.281);
    border: none;
    color: white;
    font-family: 'IBM Plex Sans';
    border-radius: 2px;
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;
    z-index: 1;
    border: none;
}

.resumeButton:hover {
    border: 1px solid white;
    transition: border 5s;
}



.infoContainer {
    height: 100%;
    width: 34%;
}

.paperContainer {
    height: 100%;
    width: 66%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
}

#aboveText {
    color: white;
    font-size: 1.3rem;
    margin-left: 5px;;
}

.bigText {
    font-size: 3rem;
}

.otherInfo {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 5px;
    font-size: 1.2rem;
}

.hobby{
    margin-bottom: 3px;
}

.contentDiv {
    display: flex;
    flex-direction: column;
    margin-top: 100px;
    margin-left: 50px;
}

.leftContent {
    width: 50%;
    color: white;
    display: flex;
    flex-direction: column;
}

.rightContent {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
}

.softwareHeading {
    font-family: 'IBM Plex Sans';
    font-weight: bolder;
    font-size: 4.5em;
}

.scrollContainer {
    height: 1em;
    overflow: hidden;
}

.profileContent {
    width: 70%;
    font-family: 'IBM Plex Sans';
    font-size: 0.9em;
}

.profileImage {
    position: absolute;
    z-index: 1;
    filter:brightness(0.9)
}


.imageGlass {
    position: absolute;
    z-index: 0;
    opacity: 0.7;
    background-color: rgba(42, 43, 46, 0.89);
    height: 200%;
    right: -500px;
    width: 1000px;
    filter: blur(2px);
    transform: rotate(20deg);
}


  @media only screen and (max-width: 1024px) {

    .profileImage {
        height: 200px;
        background-color: black;
        border-radius: 10px;
    }

    .softwareHeading {
        font-size: 2.3em;
        margin-bottom: 5px;
        width: 10%;
    }

    .contentDiv {
        margin-top: 0px;
        margin-left: 0;
    }

    .about-content {
        background-size: cover;
        background-position: center;
        flex-direction: column;
        justify-content: flex-start;
    }

    .leftContent {
        width: auto;
        padding: 30px;
        margin-top: 10px;
    }

    .rightContent {
        visibility: hidden;
        display: hidden;
        align-items: center;
        width: 100%;
        height: 0px;
    }

    .profileContent {
        width: auto;
        color:rgb(248, 248, 248);
        background-color: rgba(104, 104, 104, 0.192);
        padding: 20px;
    }

    .infoRoot {
        font-size: 7rem;
        top: 3%;
        left: 3%;
    }

    #aboveText {
        font-size: 1rem;
    }

    .bigText {
        font-size: 2rem;
    }

    .otherInfo {
        font-size: 1rem;
        margin-left: 2%;
    
    }
  }

  @media only screen and (max-width: 768px) {


    .about-content{
        flex-direction: column;
        padding: 10px;
        box-sizing: border-box;
    }

    .infoContainer {
        height: 25%;
        width: 100%;
    }

    .paperContainer{
        align-self: center;
        width: 100%;
    }

    .infoRoot {
        font-size: 7rem;
        top: 3%;
        left: 3%;
    }

    #aboveText {
        font-size: 1rem;
        margin-left: 0px;
    }

    .bigText {
        font-size: 2rem;
    }

    .otherInfo {
        display: none;
    }
  }

  @media only screen and (max-width: 375px) {
    .infoRoot {
        font-size: 7rem;
    }

    #aboveText {
        font-size: 1rem;
    }

    .bigText {
        font-size: 1.5rem;
    }
    
    .otherInfo {
        display: none;
    }
  }
