/* .section-picker-wrapper {
    position: fixed;
    right: 0px;
    z-index: 1;
    height: 0px;
    width: 0px;
    border-bottom: 100px solid transparent;
    border-top: 100px solid #1d1d1d;
    border-left: 100px solid transparent;
    border-right: 100px solid #1d1d1d;
    opacity: 0.85;
} */

.section-picker-root {
    position: absolute;
    z-index: 10;
    right: 0;
    margin: 10px 10px 0px 0px;
    text-align: right;
    transition: 0.5s ease;
    display: flex;
    flex-direction: row;
    padding: 10px;
    opacity: 0.7;
}

.section-picker-root p {
    margin: 0px 0px 10px 0px;
    padding-right: 10px;
    color: white;
    transition: 0.5s ease;
    cursor: pointer;
}

.section-picker-root p:hover:not(.activeSection){
    transition: 0.5s ease;
    margin: 0px 5px 10px 0px;
}

.section-picker-root .activeSection {
    margin: 0px 0px 10px 0px;
    transition: 0.5s ease;
}

.sliding-bar {
    position: relative;
    height: 20px;
    border-right: 2px solid white;
    transition: 1s ease;
}
