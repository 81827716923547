.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}


#section1 {
  background-image: url('./images/section1.jpg');
  background-size: cover;
  background-position: center;
  z-index: 1;
}

#section2 {
  /* background-image: url('./images/method-draw-image.svg'); */
  background-size: cover;
  background-position: center;
}

#section3 {
  /* background-image: url('./images/section3.jpg'); */
  background-size: cover;
  background-position: center;
}

#section4 {
  /* background-image: url('./images/section3.jpg'); */
  background: radial-gradient(ellipse at bottom, #2e3f52 0%, #090A0F 100%);
}

#section5 {
  background-image: url('./images/section5.jpg');
  background-size: cover;
  background-position: center;
}
