.root {
    height: 100%;
    width: 100%;
    position: absolute;
    top:0;
}

.content {
    position: absolute;
    z-index: 0;
}
