.textSection {
    height: 15%;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    align-items: center;
}



.cardSection {
    height: 75%;
    width: 100%;
    display: flex;
    box-sizing: border-box;
    padding: 20px;
}

.aboveTextDiv {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.aboveTextDiv p {
    font-size: 1.2rem;
    margin: 0px;
    font-family: 'IBM Plex Sans';
    width: calc(100% - 150px);
}

.experienceTitle {
    font-size: 24px;
}

.subtitle {
    font-size: 12px;
}

#experienceHeading {
    font-size: 5rem;
    margin: 0px;
}

.experienceContainer {
    width: 30%;
    z-index: 0;
    margin-bottom: 20px;
    border-radius: 10px;
    background-color: white;
    height: 90%;
    box-shadow: 0px 1px 3px #000000;
    display: flex;
    flex-direction: column;
}

.logoContainer {
    position: absolute;
    height: 110px;
    width: 100px;
    border-radius: 15px 100px 100px 15px;
    background-color: white;
    z-index: 1;
    top:0px;
    box-shadow: 1px 1px 3px #595959;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: #595959;
}

.logo-info-container {
    position: relative;
    z-index: 1;
    width: 100%;
    height: 110px;
}

.rows{
    display: flex;
    flex-direction: column;
    width: 100%;
}
.employmentInfoContainer {
    position: absolute;
    top: 0;
    display: flex;
    width: 100%;
    height: 110px;
    box-sizing: border-box;
    background-color: white;
    box-shadow: 1px 1px 3px #595959;
    border-radius: 10px;
    padding: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    transition: ease 0.5s;
}

.hover {
    background-color: #595959ce;
    transition: ease 0.4s;
}

.hover span {
    color: white;
    font-size: 1.5rem;
}

.hover .contentContainer {
    margin-left: 65px;
}

.cards {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
}

.contentContainer {
    margin-left: 90px;
    color: #595959;
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    width: 100%;
    justify-content: space-between;
}

.jobInfo {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
    height: 100%;
    margin-right: 15px;
}

.jobTitle {
    font-size: 1.3rem;
    margin-bottom: 5px;
    text-align: start;
}

.experienceContent {
    color: #595959;
    font-size: 0.9rem;
    text-align: start;
    padding: 10px;
    position: relative;
}

.title {
    font-size: 0.8rem;
}

.jobTime {
    display: none;
}

.technologiesContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;
}

.languagesContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;
}

.iconContainer {
    width: 55px;
    height: 55px;
    border: 1px solid #c4c4c4;
    box-shadow: 5px 5px 5px #595959;
    border-radius: 10px;
    color: #595959;
    overflow: hidden;
    margin: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.timelineParentContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 85%;
}

@media only screen and (max-width: 768px) {

    .aboveTextDiv p {
        font-size: 1rem;
        width: calc(100% - 150px);
    }

    .aboveTextDiv {
        align-items: flex-start;
    }

    #experienceHeading {
        font-size: 3rem;
    }

    .experienceContent{ 
        display: none;
    }

    .experienceContainer {
        height: 100px;
        width: 80%;
    }

    .cards {
        flex-direction: column;
        align-items: center;
    }

    .jobTime {
        margin-top: 5%;
        display: flex;
    }

    .experienceTitle {
        font-size: 18px;
        margin-bottom: 5px;
    }
    
    .subtitle {
        font-size: 10px;
    }

    .textSection {
        justify-content: flex-start;
        padding: 20px;
        height: 15%;
        width: 50%;
    }

    .timelineParentContainer {
        height: 85%;
    }


}
