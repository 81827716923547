#paperRoot{
    position: relative;
    height: 600px;
    width: 560px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

#profileCircleOuter {
    background-color:white;
    position: absolute;
    height: 215px;
    width: 215px;
    border-radius: 215px;
    z-index: 2;
    box-shadow: 3px 3px 5px #494949;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

#profileCircleInner {
    background-color: white;
    position: absolute;
    top: 7.5px;
    height: 200px;
    width: 200px;
    z-index: 3;
    border-radius: 200px;
    background-image: url('../../../images/me.png');
    background-size: cover;
}

#profileWrapper {
    position: relative;
    z-index: 1;
    top: 106px;
    min-height: calc(100% - 106px);
    background-color: white;
    border-radius: 20px;
    box-shadow: 3px 3px 5px black;
    text-align: left;
    padding: 20px;
    width: 100%;
    box-sizing: border-box;
}

#profileWrapper p {
    color: #595959;
}

#profileContent {
    position: relative;
}

.bigParaText {
    font-size: 1.2rem;
}

#profileButtons {
    display: flex;
    flex-direction: row;
    width: 100%;
    box-sizing: border-box;
    justify-content: space-between;
    padding: 20px;
    height: 106px;
}

.profileButton {
    height: 30px;
    font-family: 'Signika';
    border-radius: 5px;
    background-color: white;
    min-width: 50px;
    font-size: 1rem;
    border: 1px solid #c4c4c4;
    box-shadow: 1px 1px 3px #595959;
    color: #595959;
    cursor: pointer;
}

.profileButton:hover{
    background-color: #c4c4c4;
    transition: 0.2s ease;
    box-shadow: none;
}

.selected{
    background-color: #c4c4c4;
    transition: 0.2s ease;
    box-shadow: none;
}

.profileButton:focus{
    outline: none;
}

.stack {
 display: flex;
 flex-direction: column;
 height: 75px;
 justify-content: space-between;
}

a {
    color: inherit;
    text-decoration: none; /* no underline */
}


@media only screen and (max-width: 1024px) {
    #name {
        font-size: 7rem;
    }

    #paperRoot{
        width: 440px;
        height: 500px;
    }


    #profileCircleOuter {
        background-color:white;
        position: absolute;
        height: 150px;
        width: 150px;
        border-radius: 150px;
        z-index: 2;
        box-shadow: 3px 3px 5px #494949;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    #profileCircleInner {
        background-color: white;
        position: absolute;
        top: 7.5px;
        height: 135px;
        width: 135px;
        z-index: 3;
        border-radius: 135px;
        background-image: url('../../../images/me.png');
        background-size: cover;
    }

    #profileWrapper {
        position: relative;
        z-index: 1;
        top: 70px;
        height: calc(100% - 70px);
        background-color: white;
        border-radius: 20px;
        box-shadow: 3px 3px 5px #494949;
        text-align: left;
        padding: 20px;
        width: 100%;
    }

    #profileButtons {
        padding: 10px;
        height: 70px;
    }

    .profileButton {
        height: 20px;
        font-family: 'Signika';
        border-radius: 5px;
        background-color: white;
        min-width: 50px;
        font-size: 0.7rem;
        border: 1px solid #c4c4c4;
        box-shadow: 1px 1px 3px #595959;
        color: #595959;
    }

    .stack {
        display: flex;
        flex-direction: column;
        height: 50px;
        justify-content: space-between;
       }

    #profileContent p {
        font-size: 0.9rem;
    }
  }


  @media only screen and (max-width: 768px) {

    #paperRoot{
        width: 100%;
        height: 100%;
    }


    #profileCircleOuter {
        background-color:white;
        position: absolute;
        height: 150px;
        width: 150px;
        border-radius: 150px;
        z-index: 2;
        box-shadow: 3px 3px 5px #494949;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    #profileCircleInner {
        background-color: white;
        position: absolute;
        top: 7.5px;
        height: 135px;
        width: 135px;
        z-index: 3;
        border-radius: 135px;
        background-image: url('../../../images/me.png');
        background-size: cover;
    }

    #profileWrapper {
        position: relative;
        z-index: 1;
        top: 70px;
        height: calc(100% - 70px);
        background-color: white;
        border-radius: 20px;
        box-shadow: 3px 3px 5px #494949;
        text-align: left;
        padding: 20px;
        width: 100%;
    }

    #profileButtons {
        padding: 10px;
        height: 70px;
    }

    .profileButton {
        height: 20px;
        font-family: 'Signika';
        border-radius: 5px;
        background-color: white;
        min-width: 50px;
        font-size: 0.7rem;
        border: 1px solid #c4c4c4;
        box-shadow: 1px 1px 3px #595959;
        color: #595959;
    }

    .stack {
        display: flex;
        flex-direction: column;
        height: 50px;
        justify-content: space-between;
       }

    #profileContent p {
        font-size: 1rem;
    }
  }

  @media only screen and (max-width: 500px) {

    #paperRoot{
        width: 100%;
        height: 100%;
    }

    #profileContent p {
        font-size: 0.9rem;
    }
  }
